import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

// Add validation for environment variables
if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error(
    'Missing Supabase environment variables. Please check your .env file.'
  );
}

// Create the Supabase client
const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Test the connection
supabase.auth.getSession().catch(error => {
  console.error('Failed to initialize Supabase client:', error);
});

const supabaseService = {
  async getCategories() {
    try {
      //console.log('Fetching categories...');
      const { data, error } = await supabase
        .from('options')
        .select('*')
        .eq('parent_id', 1)
        .order('title');
      
      if (error) throw error;
      //console.log('Categories data:', data);
      return data;
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw error;
    }
  },

  async getAllDocuments() {
    const { data, error } = await supabase
      .from('docs')
      .select('*')
      .order('title');
    
    if (error) throw error;
    return data;
  },

  async getDocsByCategory(category) {
    //console.log('Fetching docs for category:', category);
    const { data, error } = await supabase
      .from('docs')
      .select('*')
      .eq('category_id', category);
    
    if (error) {
      console.error('Supabase error:', error);
      throw error;
    }
   // console.log('Docs data:', data);
    return data;
  },

  async updateDocument(docId, updateData) {
    // Clean up history before saving
    let cleanHistory = [];
    
    if (updateData.history) {
      if (Array.isArray(updateData.history)) {
        cleanHistory = updateData.history.filter(entry => 
          entry && typeof entry === 'object' && entry.date && entry.snapshot
        );
      } else if (typeof updateData.history === 'string' && updateData.history.startsWith('[{')) {
        try {
          const parsed = JSON.parse(updateData.history);
          cleanHistory = parsed.filter(entry => 
            entry && typeof entry === 'object' && entry.date && entry.snapshot
          );
        } catch (e) {
          console.warn('Failed to parse history in updateDocument:', e);
        }
      }
    }

    const dataToUpdate = {
      ...updateData,
      history: cleanHistory
    };

    const { data, error } = await supabase
      .from('docs')
      .update(dataToUpdate)
      .eq('id', docId)
      .select()
      .single();

    if (error) {
      console.error('Update error:', error);
      throw error;
    }

    // Clean up history when returning data
    let returnHistory = [];
    if (data.history) {
      if (Array.isArray(data.history)) {
        returnHistory = data.history;
      } else if (typeof data.history === 'string' && data.history.startsWith('[{')) {
        try {
          returnHistory = JSON.parse(data.history);
        } catch (e) {
          console.warn('Failed to parse history in return data:', e);
        }
      }
    }

    return {
      ...data,
      history: returnHistory
    };
  },

  async getSubcategories(categoryId) {
    try {
      const { data, error } = await supabase
        .from('options')
        .select('*')
        .eq('parent_id', categoryId)
        .order('title');
      
      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error fetching subcategories:', error);
      throw error;
    }
  },

  createDocument: async (document) => {
    const { data, error } = await supabase
      .from('docs')
      .insert([document])
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  getDocsBySubcategory: async (subcategoryId) => {
    try {
      const { data, error } = await supabase
        .from('docs')
        .select('*')
        .eq('subcategory_id', subcategoryId)
        .order('title');

      if (error) {
        throw error;
      }

      return data || [];
    } catch (error) {
      console.error('Error fetching documents by subcategory:', error);
      throw error;
    }
  },
};

export default supabaseService; 