import { MantineProvider } from '@mantine/core';
import DocExplorer2 from './components/DocExplorer2';
import '@mantine/core/styles.css';
import '@mantine/tiptap/styles.css';

function App() {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        colorScheme: 'light',
        primaryColor: 'blue',
        components: {
          Container: {
            defaultProps: {
              size: 'xl',
              px: 'xl',
              py: 'md',
            },
          },
          Paper: {
            defaultProps: {
              shadow: 'sm',
              p: 'xl',
              radius: 'md',
            },
          },
          SegmentedControl: {
            styles: (theme) => ({
              root: {
                marginBottom: '2rem',
                backgroundColor: theme.white,
              },
              label: {
                fontWeight: 500,
                padding: '8px 16px',
              },
              control: {
                border: `1px solid ${theme.colors.gray[3]}`,
              },
              indicator: {
                backgroundColor: theme.colors.blue[6],
              },
            }),
          },
          Accordion: {
            styles: (theme) => ({
              item: {
                borderRadius: '4px',
                border: `1px solid ${theme.colors.gray[3]}`,
                marginBottom: '0.5rem',
                overflow: 'hidden',
                '&:hover': {
                  backgroundColor: theme.colors.gray[0],
                },
              },
              control: {
                padding: '1rem',
                '&:hover': {
                  backgroundColor: theme.colors.gray[0],
                },
              },
              content: {
                padding: '1rem',
                backgroundColor: theme.white,
              },
            }),
          },
        },
      }}
    >
      <div className="App">
        <DocExplorer2 />
      </div>
    </MantineProvider>
  );
}

export default App; 