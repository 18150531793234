import React, { useState, useEffect, useRef } from 'react';
import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import supabaseService from '../services/supabaseService';
import { 
  SegmentedControl, 
  Accordion, 
  Container, 
  Paper, 
  Title,
  LoadingOverlay,
  Alert,
  Box,
  Text,
  Stack,
  Button,
  Group,
  Modal,
  Drawer,
  TextInput,
  Select,
  Tabs,
  ScrollArea,
} from '@mantine/core';
import { IconAlertCircle, IconRotate, IconFilter, IconEdit, IconCircleFilled, IconPlus, IconFileDownload, IconMenu2, IconSearch } from '@tabler/icons-react';
import '../styles/editor.css';
import printJS from 'print-js'; // Import the print-js library

// Category groupings
const COMMERCIAL_CATEGORIES = ['Commercial Strategy', 'Value for Money', 'IP & Innovation', 'Future Development'];
const IMPACT_CATEGORIES = ['Environmental Impact', 'Health Inequalities & Equity', 'Evidence & Evaluation', 'Clinical Safety & Effectiveness'];
const PROJECT_CATEGORIES = ['Project Management', 'Patient & Public Involvement', 'NHS Integration & Adoption', 'Technical Delivery'];

const getStatusColor = (statusId) => {
  switch (statusId) {
    case 6: // Approved
      return '#40C057';
    case 3: // Not Started
      return '#FA5252';
    case 5: // For Review
      return '#228BE6';
    case 7: // Archived
      return '#868E96';
    default: // Draft
      return '#FD7E14';
  }
};

function TiptapViewer({ content }) {
  const parsedContent = content?.replace(/\\n/g, '\n') || '';
  
  const editor = useEditor({
    extensions: [StarterKit],
    content: parsedContent,
    editable: false,
    editorProps: {
      attributes: {
        class: 'custom-editor'
      }
    }
  });

  useEffect(() => {
    if (editor && content) {
      const parsedContent = content.replace(/\\n/g, '\n') || '';
      editor.commands.setContent(parsedContent);
    }
  }, [editor, content]);

  return (
    <RichTextEditor editor={editor} styles={{
      root: { 
        border: 'none', 
        backgroundColor: '#fffff0',
        '.mantineRichTextEditorContent': {
          backgroundColor: '#fffff0',
        }
      },
      content: { 
        backgroundColor: '#fffff0',
        '.ProseMirror': { 
          padding: '1rem',
          backgroundColor: '#fffff0',
          'h2': { fontSize: '1.5rem', marginBottom: '0.8rem' },
          'h3': { fontSize: '1.2rem', marginBottom: '0.6rem' },
          'ul': { marginLeft: '1.5rem' },
          'li': { marginBottom: '0.3rem' },
          'em': { 
            display: 'inline',
            padding: 0,
            margin: 0,
            lineHeight: 'inherit',
            '&::before, &::after': {
              content: 'none'
            }
          },
        } 
      },
    }}>
      <RichTextEditor.Content />
    </RichTextEditor>
  );
}

const getDrawerTitle = (isNewDocument, isNote) => {
  const action = isNewDocument ? 'New' : 'Edit';
  const type = isNote ? 'Note' : 'Question';
  return `${action} ${type}`;
};

function EditDrawer({ opened, onClose, document, onSave, categories, isNewDocument }) {
  const [formData, setFormData] = useState({
    title: document?.title || '',
    body: document?.body || '',
    category_id: document?.category_id?.toString() || '',
    status_id: document?.status_id?.toString() || '',
    subcategory_id: document?.subcategory_id?.toString() || '',
  });

  const [subcategories, setSubcategories] = useState([]);
  const [showArchiveConfirm, setShowArchiveConfirm] = useState(false);

  const editor = useEditor({
    extensions: [StarterKit],
    content: document?.body || '',
    editorProps: {
      attributes: {
        class: 'custom-editor'
      }
    }
  });

  useEffect(() => {
    if (editor && document) {
      editor.commands.setContent(document.body || '');
      
      // First set the category_id to trigger subcategories loading
      setFormData({
        title: document.title || '',
        body: document.body || '',
        category_id: document.category_id?.toString() || '',
        status_id: document.status_id?.toString() || '',
        subcategory_id: document.subcategory_id?.toString() || '',
      });

      // Load subcategories immediately if we have a category_id
      if (document.category_id) {
        loadSubcategories(document.category_id.toString());
      }
    }
  }, [document, editor]);

  const loadSubcategories = async (categoryId) => {
    try {
      const subcatsData = await supabaseService.getSubcategories(categoryId);
      if (Array.isArray(subcatsData)) {
        setSubcategories(subcatsData);
      } else {
        console.error('Subcategories data is not an array:', subcatsData);
        setSubcategories([]);
      }
    } catch (error) {
      console.error('Failed to load subcategories:', error);
      setSubcategories([]);
    }
  };

  const getCategoryData = (categoryGroup) => {
    return categories
      .filter(cat => categoryGroup.includes(cat.title))
      .map(cat => ({
        label: cat.title,
        value: cat.id.toString()
      }));
  };

  const getSubcategoryData = () => {
    return subcategories.map(subcat => ({
      label: subcat.title,
      value: subcat.id.toString()
    }));
  };

  const handleSave = async () => {
    try {
      const editorContent = editor?.getHTML() || '';
      
      const updatedDoc = {
        title: formData.title,
        body: editorContent,
        category_id: parseInt(formData.category_id),
        subcategory_id: parseInt(formData.subcategory_id),
        status_id: parseInt(formData.status_id),
        history: []
      };

      if (document.id) {
        // Existing document - include history
        let existingHistory = [];
        if (document.history) {
          if (typeof document.history === 'string' && document.history.startsWith('[{')) {
            try {
              existingHistory = JSON.parse(document.history);
            } catch (e) {
              console.warn('Failed to parse history string:', e);
            }
          } else if (Array.isArray(document.history)) {
            existingHistory = document.history;
          }
        }

        const historyEntry = {
          date: new Date().toISOString(),
          snapshot: {
            title: document.title,
            body: document.body,
            category_id: document.category_id,
            subcategory_id: document.subcategory_id,
            status_id: document.status_id
          }
        };

        updatedDoc.history = [...existingHistory, historyEntry].filter(entry => 
          entry && typeof entry === 'object' && entry.date && entry.snapshot
        );
        
        const savedDoc = await supabaseService.updateDocument(document.id, updatedDoc);
        await onSave(savedDoc);
      } else {
        // New document
        const savedDoc = await supabaseService.createDocument(updatedDoc);
        await onSave(savedDoc);
      }
      
      onClose();
    } catch (error) {
      console.error('Failed to save document:', error);
    }
  };

  const handleStatusChange = (value) => {
    if (value === '7') { // Archive status
      setShowArchiveConfirm(true);
    } else {
      setFormData({ ...formData, status_id: value });
    }
  };

  const handleArchiveConfirm = () => {
    setFormData({ ...formData, status_id: '7' });
    setShowArchiveConfirm(false);
  };

  return (
    <>
      <Drawer
        opened={opened}
        onClose={onClose}
        title={getDrawerTitle(isNewDocument, document?.category_id === 88 || formData.category_id === '88')}
        padding="xl"
        size="50%"
        position="right"
      >
        <Stack spacing="md">
          {/* Title and Status */}
          <Group align="flex-start" spacing="md" noWrap>
            <TextInput
              label="Title"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              style={{ flex: 1 }}
            />
            <Select
              label="Status"
              data={[
                { label: 'Not Started', value: '3' },
                { label: 'Draft', value: '4' },
                { label: 'For Review', value: '5' },
                { label: 'Approved', value: '6' },
                { label: 'Archive', value: '7' }
              ]}
              value={formData.status_id}
              onChange={handleStatusChange}
              placeholder="Select a status"
              required
              style={{ width: '150px' }}
            />
          </Group>
          
          {/* Category and Subcategory */}
          {(isNewDocument ? formData.category_id !== '88' : document?.category_id !== 88) && (
            <Group grow align="flex-start" wrap="wrap">
              <Select
                label="Category"
                data={getCategoryData([...COMMERCIAL_CATEGORIES, ...IMPACT_CATEGORIES, ...PROJECT_CATEGORIES])}
                value={formData.category_id}
                onChange={(value) => {
                  setFormData({ 
                    ...formData, 
                    category_id: value,
                    subcategory_id: ''
                  });
                  loadSubcategories(value);
                }}
              />
              <Select
                label="Subcategory"
                data={getSubcategoryData()}
                value={formData.subcategory_id}
                onChange={(value) => setFormData({ ...formData, subcategory_id: value })}
                disabled={!formData.category_id}
                clearable
                placeholder="Select a subcategory"
              />
            </Group>
          )}

          {/* Text Editor - Added */}
          <Box style={{ height: '600px' }}>
            <Text weight={500} mb="xs">Content</Text>
            <RichTextEditor editor={editor} styles={{
              root: { 
                border: '1px solid #e9ecef',
                height: '550px',
              },
              content: { 
                height: '500px',
                overflow: 'auto',
              },
            }}>
              <RichTextEditor.Toolbar sticky stickyOffset={0}>
                <RichTextEditor.ControlsGroup>
                  <RichTextEditor.Bold />
                  <RichTextEditor.Italic />
                  <RichTextEditor.Underline />
                  <RichTextEditor.Strikethrough />
                  <RichTextEditor.H2 />
                  <RichTextEditor.H3 />
                  <RichTextEditor.BulletList />
                  <RichTextEditor.OrderedList />
                </RichTextEditor.ControlsGroup>
              </RichTextEditor.Toolbar>
              <RichTextEditor.Content />
            </RichTextEditor>
          </Box>

          {/* Buttons */}
          <Group position="right">
            <Button variant="outline" onClick={onClose}>Cancel</Button>
            <Button onClick={handleSave}>Save Changes</Button>
          </Group>
        </Stack>
      </Drawer>

      {/* Archive Confirmation Modal */}
      <Modal
        opened={showArchiveConfirm}
        onClose={() => setShowArchiveConfirm(false)}
        title="Confirm Archive"
        size="sm"
      >
        <Text size="sm" mb="lg">
          Are you sure you want to archive this document? Archived documents will no longer be visible in the main view.
        </Text>
        <Group position="right">
          <Button variant="outline" onClick={() => setShowArchiveConfirm(false)}>
            Cancel
          </Button>
          <Button color="red" onClick={handleArchiveConfirm}>
            Archive
          </Button>
        </Group>
      </Modal>
    </>
  );
}

const PrintableContent = React.forwardRef(({ groupedDocs }, ref) => (
  <div ref={ref} style={{ 
    position: 'fixed',
    left: '-9999px',
    top: 0,
    width: '100%',
    height: 'auto',
  }}>
    {Object.entries(groupedDocs || {}).map(([category, docs]) => (
      <div key={category} style={{ marginBottom: '30px', breakInside: 'avoid' }}>
        <h1 style={{ fontSize: '24px', marginBottom: '20px', color: '#333' }}>{category}</h1>
        {docs.map((doc) => (
          <div key={doc.id} style={{ marginBottom: '25px', breakInside: 'avoid' }}>
            <h2 style={{ fontSize: '18px', marginBottom: '10px', color: '#444' }}>{doc.title}</h2>
            <div style={{ 
              fontSize: '12px', 
              marginBottom: '10px',
              color: getStatusColor(doc.status_id)
            }}>
              Status: {
                doc.status_id === 6 ? 'Approved' :
                doc.status_id === 3 ? 'Not Started' :
                doc.status_id === 5 ? 'For Review' : 'Draft'
              }
            </div>
            <TiptapViewer content={doc.body} />
          </div>
        ))}
      </div>
    ))}
  </div>
));

const AudioPlayer = () => (
  <div className="audio-container">
    <audio controls>
      <source 
        src="https://assets.digitalhealthpassport.co/file/TMA/SBRI/Epilepsy/SBRI-Epilepsy-v2.mp3" 
        type="audio/mpeg"
      />
      Your browser does not support the audio element.
    </audio>
  </div>
);

const SlidesEmbed = () => (
  <Box>
    <iframe
      src="https://docs.google.com/presentation/d/e/2PACX-1vQlzZpx_lG4i_ljCQq-XH7mQ5VrnKXtPusUWQ6P1xnGMSrJybZmRV1syr5KZuMyQ49xvGbsGqDRkgc4/embed?start=false&loop=false&delayms=3000"
      style={{
        width: '100%',
        height: '600px',
        border: 'none',
        borderRadius: '8px',
      }}
      frameBorder="0"
      allowFullScreen={true}
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
      onError={(e) => {
        console.error('Failed to load slides:', e);
      }}
    />
    <Text size="sm" color="dimmed" mt="xs" align="center">
      If the slides don't load, you can <a href="https://docs.google.com/presentation/d/e/2PACX-1vQlzZpx_lG4i_ljCQq-XH7mQ5VrnKXtPusUWQ6P1xnGMSrJybZmRV1syr5KZuMyQ49xvGbsGqDRkgc4/pub?start=false&loop=false&delayms=3000&slide=id.p1" target="_blank" rel="noopener noreferrer">view them directly on Google Slides</a>
    </Text>
  </Box>
);

// First, add this constant near the top of the file with other constants:
const ACCORDION_STYLES = {
  item: {
    '.mantineAccordionControl': {
      padding: '0px 8px',
      minHeight: 'unset !important',
      height: 'auto !important',
    },
    '.mantineAccordionContent': {
      padding: '0px 8px',
    },
    marginTop: 0,
    '.mantineAccordionContentWrapper': {
      padding: '0 !important',
    },
    border: '1px solid #dee2e6',
    marginBottom: '4px',
    '&[dataExpanded]': {
      backgroundColor: 'transparent',
    }
  },
  control: {
    '&:hover': {
      backgroundColor: '#f8f9fa'
    }
  },
  chevron: {
    marginTop: 0
  },
  label: {
    padding: '4px 0'
  }
};

function DocExplorer2() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editDrawerOpen, setEditDrawerOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isNewDocument, setIsNewDocument] = useState(false);
  const printRef = useRef(null);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('questions');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    loadCategories();
    loadAllDocuments(); // Load all documents initially
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      loadDocuments(selectedCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedCategory) {
      loadSubcategoriesForCategory(selectedCategory);
    } else {
      setSubcategories([]);
    }
  }, [selectedCategory]);

  const loadCategories = async () => {
    try {
      const categoriesData = await supabaseService.getCategories();
      setCategories(categoriesData);
      setLoading(false);
    } catch (err) {
      setError(`Failed to load categories: ${err.message}`);
      setLoading(false);
    }
  };

  const loadAllDocuments = async () => {
    try {
      setLoading(true);
      const docsData = await supabaseService.getAllDocuments();
      // Filter out archived documents and notes
      const filteredDocs = docsData.filter(doc => 
        doc.status_id !== 7 && // Not archived
        doc.category_id !== 88  // Not notes
      );
      setDocuments(filteredDocs);
    } catch (err) {
      setError(`Failed to load documents: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const loadDocuments = async (category, subcategory = null) => {
    try {
      setLoading(true);
      let docsData;
      if (subcategory) {
        docsData = await supabaseService.getDocsBySubcategory(subcategory);
      } else {
        docsData = await supabaseService.getDocsByCategory(category);
      }
      // Filter out archived documents and notes, but only for questions tab
      docsData = docsData.filter(doc => 
        doc.status_id !== 7 && // Not archived
        doc.category_id !== 88  // Not notes
      );
      setDocuments(docsData);
    } catch (err) {
      setError(`Failed to load documents: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setSelectedCategory(null);
    setSelectedSubcategory(null);
    setSubcategories([]);
    loadAllDocuments();
  };

  const handleEditClick = (doc) => {
    setSelectedDocument(doc);
    setEditDrawerOpen(true);
  };

  const handleSaveDocument = async (updatedDoc) => {
    try {
      // Update the local documents state first
      setDocuments(prevDocs => 
        prevDocs.map(doc => 
          doc.id === updatedDoc.id ? updatedDoc : doc
        )
      );

      // Refresh the appropriate list based on active tab
      if (activeTab === 'notes') {
        await loadNotes();
      } else if (activeTab === 'questions') {
        if (selectedCategory) {
          await loadDocuments(selectedCategory);
        } else {
          await loadAllDocuments();
        }
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  const handleAddNewDocument = () => {
    setIsNewDocument(true);
    setSelectedDocument({
      title: '',
      body: '',
      category_id: activeTab === 'notes' ? '88' : '', // Set to notes category if on notes tab
      subcategory_id: '',
      status_id: '4', // Default to Draft
      history: []
    });
    setEditDrawerOpen(true);
  };

  const handlePrint = () => {
    const content = printRef.current;
    if (!content) {
      console.error('No content found to print');
      return;
    }

    const printWindow = window.open('', '_blank');
    if (!printWindow) {
      console.error('Failed to open print window');
      return;
    }

    printWindow.document.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <title>Documentation</title>
          <style>
            @page { 
              size: A4;
              margin: 20mm;
            }
            body { 
              font-family: Arial, sans-serif;
              line-height: 1.6;
              color: #333;
              margin: 0;
              padding: 20px;
            }
            h1 { 
              font-size: 24px;
              margin-bottom: 20px;
              color: #333;
            }
            h2 {
              font-size: 18px;
              margin-bottom: 10px;
              color: #444;
            }
            .status {
              font-size: 12px;
              margin-bottom: 10px;
            }
            .doc-section {
              margin-bottom: 30px;
              break-inside: avoid;
            }
            .doc-content {
              margin-bottom: 25px;
              break-inside: avoid;
            }
          </style>
        </head>
        <body>
          ${content.innerHTML}
        </body>
      </html>
    `);

    printWindow.document.close();
    
    // Wait for content to load before printing
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 250);
  };

  const loadSubcategoriesForCategory = async (categoryId) => {
    try {
      const subcatsData = await supabaseService.getSubcategories(categoryId);
      setSubcategories(subcatsData || []);
    } catch (err) {
      console.error('Failed to load subcategories:', err);
      setSubcategories([]);
    }
  };

  const handleSubcategoryClick = (subcategoryId) => {
    setSelectedSubcategory(subcategoryId);
    loadDocuments(selectedCategory, subcategoryId);
  };

  // Update the loadNotes function
  const loadNotes = async () => {
    try {
      setLoading(true);
      const docsData = await supabaseService.getAllDocuments();
      // Only keep notes (category_id === 88) and non-archived documents
      const notesData = docsData.filter(doc => 
        doc.status_id !== 7 && // Not archived
        doc.category_id === 88  // Only notes
      );
      setDocuments(notesData);
    } catch (err) {
      setError(`Failed to load notes: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  // Update the tab change handler
  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
    if (newTab === 'notes') {
      loadNotes();
    } else if (newTab === 'questions') {
      if (selectedCategory) {
        loadDocuments(selectedCategory);
      } else {
        loadAllDocuments();
      }
    }
    // Reset category selection when switching to non-questions tabs
    if (newTab !== 'questions') {
      setSelectedCategory(null);
      setSelectedSubcategory(null);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }

    // Load all documents including notes
    supabaseService.getAllDocuments().then(allDocs => {
      // Filter out only archived documents, but keep both questions and notes
      const filteredDocs = allDocs.filter(doc => doc.status_id !== 7);
      
      const filteredResults = filteredDocs.filter(doc => {
        const searchTerm = query.toLowerCase();
        const titleMatch = doc.title?.toLowerCase().includes(searchTerm);
        const bodyMatch = doc.body?.toLowerCase().includes(searchTerm);
        return titleMatch || bodyMatch;
      });

      setSearchResults(filteredResults);
    }).catch(error => {
      console.error('Error loading documents for search:', error);
      setSearchResults([]);
    });
  };

  if (error) {
    return (
      <Box px="md" maw={1600} mx="auto">
        <Alert icon={<IconAlertCircle size="1rem" />} title="Error" color="red" radius="md">
          {error}
        </Alert>
      </Box>
    );
  }

  return (
    <Box px="md" maw={1600} mx="auto">
      <PrintableContent 
        ref={printRef}
        groupedDocs={documents.reduce((acc, doc) => {
          const category = categories.find(c => c.id === doc.category_id)?.title || 'Uncategorized';
          if (!acc[category]) acc[category] = [];
          acc[category].push(doc);
          return acc;
        }, {})}
      />

      {/* Mobile Categories Drawer */}
      <Drawer
        opened={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        padding="md"
        size="100%"
        position="left"
      >
        <Stack spacing="xs">
          <Button
            variant={selectedCategory === null ? "filled" : "light"}
            fullWidth
            onClick={() => {
              handleReset();
              setMobileMenuOpen(false);
            }}
            mb="xs"
          >
            All Documents
          </Button>
          {categories
            .filter(cat => cat.parent_id === 1)
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(category => (
              <Box key={category.id}>
                <Button
                  variant={selectedCategory === category.id && selectedSubcategory === null ? "filled" : "light"}
                  fullWidth
                  onClick={() => {
                    setSelectedCategory(category.id);
                    setSelectedSubcategory(null);
                    loadDocuments(category.id);
                    setMobileMenuOpen(false);
                  }}
                >
                  {category.title}
                </Button>
                
                {/* Show subcategories when parent category is selected */}
                {selectedCategory === category.id && subcategories.length > 0 && (
                  <Stack spacing={8} ml={20} mt={8}>
                    {subcategories.map((subcat) => (
                      <Button
                        key={subcat.id}
                        size="sm"
                        variant={selectedSubcategory === subcat.id ? "filled" : "light"}
                        fullWidth
                        onClick={() => {
                          handleSubcategoryClick(subcat.id);
                          setMobileMenuOpen(false);
                        }}
                      >
                        {subcat.title}
                      </Button>
                    ))}
                  </Stack>
                )}
              </Box>
            ))}
        </Stack>
      </Drawer>

      <Group 
        align="flex-start" 
        spacing="lg" 
        w="100%" 
        style={{ 
          position: 'relative',
          maxWidth: '1600px',  // Match the Box container's max width
          margin: '0 auto',    // Center the group
        }}
      >
        {/* Categories Panel */}
        <Box 
          p="md" 
          style={{ 
            width: '250px', 
            flexShrink: 0,
          }}
          display={{ base: 'none', md: 'block' }}
        >
          {activeTab === 'questions' && (
            <Stack spacing="xs">
              <Button
                variant={selectedCategory === null ? "filled" : "light"}
                fullWidth
                onClick={handleReset}
                mb="xs"
              >
                All Questions
              </Button>
              {categories
                .filter(cat => cat.parent_id === 1)
                .sort((a, b) => a.title.localeCompare(b.title))
                .map(category => (
                  <Box key={category.id}>
                    <Button
                      variant={selectedCategory === category.id && selectedSubcategory === null ? "filled" : "light"}
                      fullWidth
                      onClick={() => {
                        setSelectedCategory(category.id);
                        setSelectedSubcategory(null);
                        loadDocuments(category.id);
                      }}
                    >
                      {category.title}
                    </Button>
                    
                    {/* Show subcategories when parent category is selected */}
                    {selectedCategory === category.id && subcategories.length > 0 && (
                      <Stack spacing={8} ml={20} mt={8}>
                        {subcategories.map((subcat) => (
                          <Button
                            key={subcat.id}
                            size="sm"
                            variant={selectedSubcategory === subcat.id ? "filled" : "light"}
                            fullWidth
                            onClick={() => handleSubcategoryClick(subcat.id)}
                          >
                            {subcat.title}
                          </Button>
                        ))}
                      </Stack>
                    )}
                  </Box>
                ))}
            </Stack>
          )}
        </Box>

        {/* Modified Main Content Area */}
        <Paper 
          shadow="sm" 
          p="0"
          radius="md" 
          pos="relative" 
          style={{ flex: 1 }}
        >
          <LoadingOverlay visible={loading} blur={2} />
          
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tabs.List>
              <Tabs.Tab value="questions">Questions</Tabs.Tab>
              <Tabs.Tab value="notes">Notes</Tabs.Tab>
              <Tabs.Tab value="search">Search</Tabs.Tab>
              <Tabs.Tab value="audio">Audio</Tabs.Tab>
              <Tabs.Tab value="slides">Slides</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="questions">
              <Box mb="xl" mt="md" px="md">
                <Group position="apart" mb="lg">
                  <Title order={2}>
                    Questions: {selectedCategory 
                      ? categories.find(c => c.id === selectedCategory)?.title || 'All'
                      : 'All'}
                  </Title>
                  <Group>
                    <Button 
                      leftSection={<IconMenu2 size="1.2rem" />}
                      variant="light"
                      onClick={() => setMobileMenuOpen(true)}
                      display={{ base: activeTab === 'questions' ? 'block' : 'none', md: 'none' }}
                    >
                      Categories
                    </Button>
                    <Button 
                      leftSection={<IconPlus size="1.2rem" />}
                      variant="light"
                      onClick={handleAddNewDocument}
                    >
                      Add Question
                    </Button>
                    {documents.length > 0 && (
                      <Button 
                        leftSection={<IconFileDownload size="1.2rem" />}
                        variant="light"
                        onClick={handlePrint}
                        loading={loading}
                      >
                        Download PDF
                      </Button>
                    )}
                  </Group>
                </Group>
                
                <ScrollArea 
                  h="calc(100vh - 140px)"
                  type="hover"
                  styles={(theme) => ({
                    scrollbar: {
                      '&[data-orientation="vertical"]': {
                        width: '8px',
                      },
                    },
                    thumb: {
                      backgroundColor: '#228BE6',  // Updated to the specified blue color
                    }
                  })}
                >
                  <Accordion 
                    variant="unstyled" 
                    radius="md" 
                    styles={ACCORDION_STYLES}
                  >
                    {documents.map(doc => (
                      <Accordion.Item key={doc.id} value={doc.id.toString()}>
                        <Accordion.Control>
                          <Group spacing="xs" align="flex-start" wrap="nowrap">
                            <IconCircleFilled 
                              size="0.8rem" 
                              style={{ 
                                color: getStatusColor(doc.status_id),
                                flexShrink: 0,
                                marginTop: '0.4rem'
                              }} 
                            />
                            <Text size="md" fw={600} style={{ lineHeight: 1.2, flex: 1 }}>
                              {doc.title}
                            </Text>
                          </Group>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <Box 
                            style={{ 
                              position: 'relative',
                              backgroundColor: '#fffff0',
                              padding: '0.25rem',
                              borderRadius: '0 0 8px 8px'
                            }}
                          >
                            <TiptapViewer content={doc.body} />
                            <Button
                              variant="subtle"
                              size="sm"
                              p={4}
                              onClick={() => handleEditClick(doc)}
                              style={{ 
                                position: 'absolute',
                                bottom: '0.5rem',
                                right: '0.5rem'
                              }}
                            >
                              <IconEdit size="1.2rem" />
                            </Button>
                          </Box>
                        </Accordion.Panel>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </ScrollArea>
              </Box>
            </Tabs.Panel>

            <Tabs.Panel value="notes">
              <Box mb="xl" mt="md" px="md">
                <Group position="apart" mb="lg">
                  <Title order={2}>Notes</Title>
                  <Group>
                    <Button 
                      leftSection={<IconPlus size="1.2rem" />}
                      variant="light"
                      onClick={() => {
                        setIsNewDocument(true);
                        setSelectedDocument({
                          title: '',
                          body: '',
                          category_id: '88',
                          subcategory_id: '',
                          status_id: '4',
                          history: []
                        });
                        setEditDrawerOpen(true);
                      }}
                    >
                      Add Note
                    </Button>
                    {documents.length > 0 && (
                      <Button 
                        leftSection={<IconFileDownload size="1.2rem" />}
                        variant="light"
                        onClick={handlePrint}
                        loading={loading}
                      >
                        Download PDF
                      </Button>
                    )}
                  </Group>
                </Group>

                <ScrollArea 
                  h="calc(100vh - 140px)"
                  type="hover"
                  styles={(theme) => ({
                    scrollbar: {
                      '&[data-orientation="vertical"]': {
                        width: '8px',
                      },
                    },
                    thumb: {
                      backgroundColor: '#228BE6',  // Updated to the specified blue color
                    }
                  })}
                >
                  <Accordion 
                    variant="unstyled" 
                    radius="md" 
                    styles={ACCORDION_STYLES}
                  >
                    {documents
                      .filter(doc => doc.category_id === 88)
                      .map(doc => (
                        <Accordion.Item key={doc.id} value={doc.id.toString()}>
                          <Accordion.Control>
                            <Group spacing="xs" align="flex-start" wrap="nowrap">
                              <IconCircleFilled 
                                size="0.8rem" 
                                style={{ 
                                  color: getStatusColor(doc.status_id),
                                  flexShrink: 0,
                                  marginTop: '0.4rem'
                                }} 
                              />
                              <Text size="md" fw={600} style={{ lineHeight: 1.2, flex: 1 }}>
                                {doc.title}
                              </Text>
                            </Group>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <Box 
                              style={{ 
                                position: 'relative',
                                backgroundColor: '#fffff0',
                                padding: '0.25rem',
                                borderRadius: '0 0 8px 8px'
                              }}
                            >
                              <TiptapViewer content={doc.body} />
                              <Button
                                variant="subtle"
                                size="sm"
                                p={4}
                                onClick={() => handleEditClick(doc)}
                                style={{ 
                                  position: 'absolute',
                                  bottom: '0.5rem',
                                  right: '0.5rem'
                                }}
                              >
                                <IconEdit size="1.2rem" />
                              </Button>
                            </Box>
                          </Accordion.Panel>
                        </Accordion.Item>
                      ))}
                  </Accordion>
                </ScrollArea>
              </Box>
            </Tabs.Panel>

            <Tabs.Panel value="search">
              <Box mb="xl" mt="md" px="md">
                <Group position="apart" mb="lg">
                  <Title order={2}>Search Documents</Title>
                </Group>

                <TextInput
                  placeholder="Search all documents..."
                  icon={<IconSearch size="1.1rem" />}
                  value={searchQuery}
                  onChange={(event) => handleSearch(event.currentTarget.value)}
                  mb="md"
                />

                <ScrollArea 
                  h="calc(100vh - 200px)"
                  type="hover"
                  styles={(theme) => ({
                    scrollbar: {
                      '&[data-orientation="vertical"]': {
                        width: '8px',
                      },
                    },
                    thumb: {
                      backgroundColor: '#228BE6',
                    }
                  })}
                >
                  <Accordion 
                    variant="unstyled" 
                    radius="md" 
                    styles={ACCORDION_STYLES}
                    defaultValue={searchResults.map(doc => doc.id.toString())} // All items default to open
                    multiple // Allow multiple items to be open
                  >
                    {searchResults.map(doc => (
                      <Accordion.Item key={doc.id} value={doc.id.toString()}>
                        <Accordion.Control>
                          <Group spacing="xs" align="flex-start" wrap="nowrap">
                            <IconCircleFilled 
                              size="0.8rem" 
                              style={{ 
                                color: getStatusColor(doc.status_id),
                                flexShrink: 0,
                                marginTop: '0.4rem'
                              }} 
                            />
                            <Stack spacing={2}>
                              <Text size="md" fw={600} style={{ lineHeight: 1.2, flex: 1 }}>
                                {doc.title}
                              </Text>
                              <Text size="xs" color="dimmed">
                                {doc.category_id === 88 ? 'Note' : 'Question'}
                              </Text>
                            </Stack>
                          </Group>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <Box 
                            style={{ 
                              position: 'relative',
                              backgroundColor: '#fffff0',
                              padding: '0.25rem',
                              borderRadius: '0 0 8px 8px'
                            }}
                          >
                            <TiptapViewer content={doc.body} />
                            <Button
                              variant="subtle"
                              size="sm"
                              p={4}
                              onClick={() => handleEditClick(doc)}
                              style={{ 
                                position: 'absolute',
                                bottom: '0.5rem',
                                right: '0.5rem'
                              }}
                            >
                              <IconEdit size="1.2rem" />
                            </Button>
                          </Box>
                        </Accordion.Panel>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                  {searchQuery && searchResults.length === 0 && (
                    <Text color="dimmed" align="center" mt="xl">
                      No documents found matching your search.
                    </Text>
                  )}
                </ScrollArea>
              </Box>
            </Tabs.Panel>

            <Tabs.Panel value="audio">
              <Box p="md">
                <Title order={2} mb="lg">Audio Guide</Title>
                <AudioPlayer />
              </Box>
            </Tabs.Panel>

            <Tabs.Panel value="slides">
              <Box p="md">
                <Title order={2} mb="lg">Presentation Slides</Title>
                <SlidesEmbed />
              </Box>
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </Group>

      <EditDrawer
        opened={editDrawerOpen}
        onClose={() => {
          setEditDrawerOpen(false);
          setIsNewDocument(false);
        }}
        document={selectedDocument}
        onSave={async (savedDoc) => {
          if (isNewDocument) {
            // If it's a new document, add it to the documents list
            setDocuments(prev => [...prev, savedDoc]);
          }
          await handleSaveDocument(savedDoc);
          
          // Refresh notes list if we're in notes tab
          if (activeTab === 'notes') {
            await loadNotes();
          }
          
          setIsNewDocument(false);
        }}
        categories={categories}
        isNewDocument={isNewDocument}
      />
    </Box>
  );
}

export default DocExplorer2;